.FRContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}
  
.FRContainer.rtl {
    direction: rtl;
    text-align: right;
}



.FRFormCard {
    background: var(--secondarycolorvar);
    padding: 20px;
    border-radius: 20px;
    /* box-shadow: 8px 8px 16px #b8b8b8, -8px -8px 16px #ffffff; */
    width: 100%;
    max-width: 400px;
    text-align: center;
}
  
.FRFormTitle {
    font-size: 26px;
    margin-bottom: 20px;
    color: #000000;
}
  
.FRFormGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.FRLanguageToggle {
    background: #f39c12;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: 4px 4px 8px #b8b8b8, -4px -4px 8px #ffffff;
    transition: 0.3s;
}
  
.FRLanguageToggle:hover {
    background: #e67e22;
}
  


.FRInputContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
}
  
.FRContainer.rtl .FRInputContainer {
    text-align: right;
}


.FRInputContainer label {
    font-size: 20px;
    margin-bottom: 5px;
    color: #000000;
}


.FR-SkillsContainer {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 1.5rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}
  
.FR-SkillsContainer .FRLabel {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.FR-SkillsContainer .FRSkillContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: inset 2px 2px 5px #ddd, inset -2px -2px 5px #fff;
}




.FRSubmitBtn {
    padding: 10px;
    border: none;
    border-radius: 10px;
    background: #5b9dc8;
    color: rgb(0, 0, 0);
    font-size: 22px;
    cursor: pointer;
    box-shadow: 2px 2px 4px #b8b8b8, -2px -2px 4px #ffffff;
    transition: 0.3s;
    width: 100%;
}
  
.FRSubmitBtn:hover {
    background: #256088;
}




@media (max-width: 480px) {
    .FRFormCard {
      padding: 15px;
    }
    .FRFormTitle {
      font-size: 20px;
    }

    .FRSubmitBtn {
      font-size: 14px;
      padding: 8px;
    }
    .FRLanguageToggle {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
  
  @media (max-width: 900px) {

  
  }