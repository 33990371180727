.CoverAllRequests{
    height: 80vh;
    overflow-y: auto;
    min-height: 79vh;
    max-height: 80vh;
    overflow-x: hidden;
    background-color: var(--bgcolorvar);
    display:grid;
    grid-template-rows: repeat(3,33.33%);
    justify-items: center;
    align-items: center;
    
}


.AllRequestsText{
    font-family: "AlfaSlabOne";
    background-color: var(--addresscolorvar );
    border: 3px solid var(--bordercolorvar );
    border-radius: 25%;
    font-size: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 80%;
    grid-row: 1/2;

}
.AllRequestsText p {
    margin: 0;
    text-wrap: nowrap;

}



.AllRequestsDiv{
    border: 3px solid var(--bordercolorvar );
    border-radius: 5%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-row: 2/5;

}






@media screen and (width < 992px) {

}


@media screen and (width < 1500px) {

    

}