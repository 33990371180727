.FLFDContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.FLFDContainer.rtl {
  direction: rtl;
  text-align: right;
}

.FLFDFormCard {
  background: var(--secondarycolorvar);
  padding: 20px;
  border-radius: 20px;
  /* box-shadow: 8px 8px 16px #b8b8b8, -8px -8px 16px #ffffff; */
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.FLFDFormTitle {
  font-size: 26px;
  margin-bottom: 20px;
  color: #000000;
}

.FLFDFormGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.FLFDInputContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.FLFDContainer.rtl .FLFDInputContainer {
  text-align: right;
}

.FLFDInputContainer label {
  font-size: 20px;
  margin-bottom: 5px;
  color: #000000;
}

.FLFDInputContainer input {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #e0e0e0;
  box-shadow: inset 5px 5px 10px #b8b8b8, inset -5px -5px 10px #ffffff;
  font-size: 20px;
  outline: none;
  color: #000000;
  width: 100%;
  max-width: 100%;
}

.FLFDInputContainer select {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #e0e0e0;
  box-shadow: inset 5px 5px 10px #b8b8b8, inset -5px -5px 10px #ffffff;
  font-size: 16px;
  outline: none;
  color: #333;
  width: 100%;
}

.FLFDCheckboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.FLFDCheckboxGroup label {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #e0e0e0;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: inset 3px 3px 6px #b8b8b8, inset -3px -3px 6px #ffffff;
  cursor: pointer;
  font-size: 14px;
}

.FLFD-SkillsContainer {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 1.5rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.FLFD-SkillsContainer .FLFDLabel {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.FLFD-SkillsContainer .FLFDNote {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.FLFD-SkillsContainer .FLFDCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FLFD-SkillsContainer .FLFDSkillContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: inset 2px 2px 5px #ddd, inset -2px -2px 5px #fff;
}

.FLFD-SkillsContainer .FLFDCheckbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.FLFD-SkillsContainer .FLFDCheckbox input {
  width: 1.1rem;
  height: 1.1rem;
}

.FLFD-SkillsContainer .FLFDProficiency {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.FLFD-SkillsContainer .FLFDProficiencyLabel {
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
}

.FLFD-SkillsContainer .FLFDProficiency input[type="range"] {
  width: 100%;
  flex: 1;
  -webkit-appearance: none;
  height: 5px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  transition: 0.3s;
}

.FLFD-SkillsContainer .FLFDProficiency input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.FLFD-SkillsContainer .FLFDPercentage {
  font-size: 0.9rem;
  font-weight: bold;
  color: #007bff;
}

.FLFDAgreeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: inset 3px 3px 6px #b8b8b8, inset -3px -3px 6px #ffffff;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  margin-top: 15px;
}

.FLFDAgreeContainer input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.FLFDAgreeContainer label {
  cursor: pointer;
}

.FLFDContainer.rtl .FLFDAgreeContainer {
  text-align: right;
  justify-content: flex-end;
}


.FLFDSubmitBtn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #5b9dc8;
  color: rgb(0, 0, 0);
  font-size: 22px;
  cursor: pointer;
  box-shadow: 2px 2px 4px #b8b8b8, -2px -2px 4px #ffffff;
  transition: 0.3s;
  width: 100%;
}

.FLFDSubmitBtn:hover {
  background: #256088;
}

.FLFDLanguageToggle {
  background: #f39c12;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: 4px 4px 8px #b8b8b8, -4px -4px 8px #ffffff;
  transition: 0.3s;
}

.FLFDLanguageToggle:hover {
  background: #e67e22;
}

@media (max-width: 480px) {
  .FLFDFormCard {
    padding: 15px;
  }
  .FLFDFormTitle {
    font-size: 20px;
  }
  .FLFDInputContainer input {
    font-size: 14px;
    padding: 8px;
  }
  .FLFDSubmitBtn {
    font-size: 14px;
    padding: 8px;
  }
  .FLFDLanguageToggle {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 900px) {

.FLFDProficiency {
  display: flex;
  flex-direction: column; /* يجعل العناصر تظهر على شكل عمود (سطر جديد لكل عنصر) */
  align-items: start; /* يجعل العناصر تبدأ من اليسار */
  gap: 8px; /* مسافة بين العناصر */
}

.FLFDProficiencyLabel {
  font-weight: bold;
}

input[type="range"] {
  width: 100%; /* ليملأ العرض بالكامل */
}

}